import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from '~components';
import CareerCard from './Components/Card'
import Career from './style'
export default function CareerDetailsSection() {
  return (
    <Career className='career_container'  backgroundColor="#f3f4f6">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-8 col-lg-10">
            <CareerCard />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          <Col className="col-xl-8 col-lg-10">
            <Career.Wrapper>
              <Career.ContentBlock>
                {/* <Career.Title fontColor="#262729">About the job</Career.Title> */}
                <Career.Text>Somos una Corporación Global con diversos intereses e inversiones en España, Canadá y
Latinoamérica. ¡Transformamos startups en fuertes empresas de tecnología! Estamos
creciendo, y buscamos integrar a nuestro equipo una persona apasionada por la
administración de oficina.</Career.Text>
                {/* <Career.Text>In this role, you will work cross-functionally to guide products
              from
              conception to launch by connecting the technical and business worlds. You can break down complex problems
              into steps that drive product development at lightning speed.</Career.Text> */}
              </Career.ContentBlock>
              <Career.ContentBlock>
                <Career.Title fontColor="#262729">¿Qué necesitas?
                </Career.Title>
                {/* <Career.Text>A Senior Data Engineer provides technical and delivery leadership
              for a team of developers working on data integration and processing projects. This role will work with
              stakeholders and other developers to design and implement technical data solutions for the business in a
              way that balances quality, maintainability cost, and time.</Career.Text> */}
                <Career.List>
                  <Career.ListItem>Educación: Licenciatura en Administración Hotelera, Administración de empresas concluida.</Career.ListItem>
                  <Career.ListItem>Conocimientos de administración de edificios u oficinas Indispensable</Career.ListItem>
                  <Career.ListItem>Dominio de la suite Microsoft Office y Google Suite
                  </Career.ListItem>
                  <Career.ListItem>Experiencia en gestión de suministros.</Career.ListItem>
                  <Career.ListItem>Excelentes habilidades de comunicación escrita y oral.</Career.ListItem>
                  <Career.ListItem>Capacidad de escribir código.</Career.ListItem>
                  <Career.ListItem>Conocimiento de normas de seguridad / protección civil.</Career.ListItem>
                </Career.List>
              </Career.ContentBlock>
              <Career.ContentBlock>
                <Career.Title fontColor="#262729">Funciones</Career.Title>
                <Career.List>

                  <Career.ListItem>Serás el responsable de asegurar el buen funcionamiento de la oficina de la empresa. </Career.ListItem>
                  <Career.ListItem>Gestionar el mantenimiento, proveedores, material y otras tareas de tipo administrativo.</Career.ListItem>
                  <Career.ListItem>Desarrollar procedimientos y sistemas organizativos para el personal de oficina, incluyendo la
presentación de informes, la facturación, las cuentas por pagar, la nómina y la programación.</Career.ListItem>
                  <Career.ListItem>Hacer pedidos de suministros y equipo según sea necesario.</Career.ListItem>
                  <Career.ListItem>Encargada de arrendamiento de oficinas.</Career.ListItem>
                  {/* <Career.ListItem>Investigar y recomendar los sistemas de gestión de contenido más efectivos.</Career.ListItem>
                  <Career.ListItem>Gestionar la investigación y el desarrollo de tecnología, activos de TI e ingresos asociados</Career.ListItem>
                  <Career.ListItem>Desarrollar procedimientos de seguridad.</Career.ListItem> */}
                </Career.List>
              </Career.ContentBlock>
              {/* <Career.ContentBlock>
                <Career.Title fontColor="#262729">Fuertes Habilidades</Career.Title>
                <Career.List>

                  <Career.ListItem>Resolución de problemas
                  </Career.ListItem>
                  <Career.ListItem>Autogestión y responsabilidad</Career.ListItem>
                  <Career.ListItem>Trabajo en equipo
                  </Career.ListItem>
                  <Career.ListItem>Comunicación</Career.ListItem>
                 
                </Career.List>
              </Career.ContentBlock> */}
              <Career.ContentBlock>
                <Career.Title fontColor="#262729">Beneficios
                </Career.Title>
                <Career.List>

                  <Career.ListItem>Contratación directa
                  </Career.ListItem>
                  <Career.ListItem>Prestaciones de Ley y superiores</Career.ListItem>
                  <Career.ListItem>Vacaciones de ley y adicionales
                  </Career.ListItem>
                  <Career.ListItem>SGMM Salario Competitivo</Career.ListItem>
                  <Career.ListItem>Crecimiento a corto plazo</Career.ListItem>
                  {/* <Career.ListItem>Crecimiento a corto plazo</Career.ListItem> */}
                </Career.List>
              </Career.ContentBlock>
              {/* <Career.ContentBlock>
                <Career.Title fontColor="#262729">How to apply</Career.Title>
                <Career.Text mb="30px">At Fastland we celebrate everyone and their multiple intersecting identities. We believe that a panorama of experience allows us to make better decisions together and inspires so that we can better serve our customers and community. Our goal is for metsys user to feel deeply connected to their team through mutual value, respect, and belief.</Career.Text>
                <Career.Button to='/'>Apply for this position</Career.Button>
              </Career.ContentBlock> */}
            </Career.Wrapper>
          </Col>
        </Row>
      </Container>
    </Career>

  )
}